jQuery(document).ready(function($) {

    function voteSuccessfull(nomineeID) {
        // ADD ADDITIONAL CODE HERE
		window.disableVoting(nomineeID);
		window.storeVotingStatus(nomineeID);
		window.closeDialog(true);
    }

    function reloadCaptcha(responseContainer) {
        $('.voting-form-nominee .captcha').css('visibility', 'hidden');
        $.ajax({
            type: 'POST',
            url: $('form.voting-form-nominee').attr('action'),
            data: {
                'action': 'mw_vs_get_captcha'
            },
            success: function(response) {
                if(!response || !response.data) return;
                $('.voting-form-nominee .captcha').attr('src', 'data:image/jpeg;base64,' + response.data.captcha);
                $('.voting-form-nominee .captchaid').val(response.data.id);
                $('.voting-form-nominee .captcha').css('visibility', 'visible');
            },
            error: function() {
                if(responseContainer) {
                    responseContainer.find('.error').css('display', 'block');
                }
            }
        });
    }

    function initVotingSystem() {

        $('.reload-captcha').on('click', function(e) {
            e.preventDefault();
            reloadCaptcha();
        })

        $('.voting-form-nominee').submit(function(e) {
            e.preventDefault();
            var formData = $(this).serialize();
            var responseContainer = $(this).find('.reponse');
            var form = $(this);
            var nomineeID = $(this).find("input[name=nominee_id]").val();
    
            if(form.attr('disabled')) return;
    
            formData += '&action=mw_vs_register_vote';
    
            responseContainer.find('div').css('display', 'none');
    
            form.attr('disabled', true);
            form.find('input,button').attr('disabled', true);
            form.addClass('working');
    
            $.ajax({
                type: 'POST',
                url: $(this).attr('action'),
                data: formData,
                success: function(response) {
                    form.removeClass('working');
    
                    if(response && response.success) {
                        responseContainer.find('.success').css('display', 'block');
                        voteSuccessfull(nomineeID);
                        return;
                    } 
    
                    form.attr('disabled', false);
                    form.find('input,button').attr('disabled', false);
                    
                    if(response && response.data == "CAPTCHA_WRONG") {
                        reloadCaptcha(responseContainer);
                        form.find('#captcha').val('');
                        responseContainer.find('.captcha-wrong').css('display', 'block');                    
                    } else if(response && response.data == "EMAIL_WRONG") {
                        responseContainer.find('.email-wrong').css('display', 'block');                    
                    } else if(response && response.data == "ALREADY_VOTED") {
                        responseContainer.find('.already-voted').css('display', 'block');                    
                    } else if(response && response.data == "VOTE_FINISHED") {
                        responseContainer.find('.voted-finished').css('display', 'block');
                    } else if(response && response.data == "NOONCE_WRONG") {
                        responseContainer.find('.voted-finished').css('display', 'block');
                    } else if(response && response.data == "FIELDS_MISSING") {
                        responseContainer.find('.fields-missing').css('display', 'block');
                    } else{
                        responseContainer.find('.error').css('display', 'block');
                    }
                },
                error: function(xhr, status, error) {
                    console.error('Error occurred while submitting the form', error);
                    responseContainer.find('.error').css('display', 'block');
                    form.removeClass('working');
                    form.attr('disabled', false);
                    form.find('input,button').attr('disabled', false);
                }
            });
        });

        reloadCaptcha();
    }

    initVotingSystem();

    window.initVotingSystem = initVotingSystem;
});